//Work on this

import React from 'react'
import IntroDesc from './IntroDesc'
import MarketingVideo from './MarketingVideo'
import PastClientLogos from './PastClientLogos'


const Intro = () => {

    return (
        <React.Fragment>
            <div className="intro-container">
                <div className="intro-desc">
                    <IntroDesc />
                </div>
                <div className="intro-video">
                    <MarketingVideo />
                    <p id="intro-desc-top-p2">“People in our industry continue to define "creative" very narrowly. It's not just about Don Draper's "Mad Men" anymore. You can, believe it or not, have a creative data scientist or media planner."
                    </p>
                    <p id="intro-desc-top-p2">- Sir Martin Sorrell</p>
                </div>
            </div>
            <PastClientLogos />
        </React.Fragment>
    )
}

export default Intro
