import React, { useState } from 'react'

const PastClientLogos = () => {
    const [logo02, setLogo02] = useState(false);
    const [logo03, setLogo03] = useState(false);
    const [logo1, setLogo1] = useState(false);
    const [logo2, setLogo2] = useState(false);
    const [logo3, setLogo3] = useState(false);
    const [logo4, setLogo4] = useState(false);
    const [logo5, setLogo5] = useState(false);
    const [logo6, setLogo6] = useState(false);
    const [logo7, setLogo7] = useState(false);
    const [logo8, setLogo8] = useState(false);
    const [logo9, setLogo9] = useState(false);

    return (
        <div>
            <p id='past-clients-desc'>Client Experience</p>
            <div className='past-client-logos-container'>
        


                <div onMouseOver={() => setLogo02(true)} onMouseLeave={() => setLogo02(false)}>
                    {logo02 === false ?
                        <a href='https://calcattlecouncil.org/' target='_blank' rel="noopener noreferrer">
                            <img src={require('../images/CCC-white.png')} className='past-client' alt='California Cattle Council' id='CalCCLogo' />
                        </a> : <a href='https://calcattlecouncil.org/' target='_blank' rel="noopener noreferrer">
                            <img src={require('../images/CCC-color1.png')} className='past-client' alt='California Cattle Council' id='CalCCLogo' />
                        </a>}
                </div>

                <div onMouseOver={() => setLogo03(true)} onMouseLeave={() => setLogo03(false)}>
                    {logo03 === false ?
                        <a href='https://chocolatefishcoffee.com/' target='_blank' rel="noopener noreferrer">
                            <img src={require('../images/chocolate-fish-white.png')} className='past-client' alt='Chocolate Fish Coffee Roasters' id='Coffee' />
                        </a> : <a href='https://chocolatefishcoffee.com/' target='_blank' rel="noopener noreferrer">
                            <img src={require('../images/chocolate-fish-color.png')} className='past-client' alt='Chocolate Fish Coffee Roasters' id='Coffee' />
                        </a>}
                </div>
        
        
                <div onMouseOver={()=>setLogo1(true)} onMouseLeave={()=> setLogo1(false)}>
                    { logo1 === false? 
                <a href='https://www.cccco.edu/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/CCCLogo-grey.png')} className='past-client-logos' alt='California Community Colleges' id='cCCLogo' />
                </a> : <a href='https://www.cccco.edu/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/CCCLogo.png')} className='past-client-logos' alt='California Community Colleges' id='cCCLogo' />
                </a>}
                </div>
                <div onMouseOver={()=>setLogo2(true)} onMouseLeave={()=> setLogo2(false)}>
                    { logo2 === false? 
                 <a href='https://www.statefundca.com/' target='_blank' rel="noopener noreferrer">
                 <img src={require('../images/StateFundLogo-grey.png')} className='past-client-logos' alt='State Fund' id='stateFundLogo' />
             </a>:   <a href='https://www.statefundca.com/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/StateFundLogo.png')} className='past-client-logos' alt='State Fund' id='stateFundLogo' />
                </a>}
                </div>
                <div onMouseOver={()=>setLogo3(true)} onMouseLeave={()=> setLogo3(false)}>
                    { logo3 === false? 
                   <a href='https://www.magnumicecream.com/us/en/home.html' target='_blank' rel="noopener noreferrer">
                   <img src={require('../images/magnumLogo-grey.png')} className='past-client-logos' alt='Magnum' />
               </a> :    <a href='https://www.magnumicecream.com/us/en/home.html' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/magnumLogo.png')} className='past-client-logos' alt='Magnum' />
                </a>}
                </div>
                <div onMouseOver={()=>setLogo4(true)} onMouseLeave={()=> setLogo4(false)}>
                    { logo4 === false? 
                 <a href='https://sacramento.aero/smf' target='_blank' rel="noopener noreferrer">
                 <img src={require('../images/SIALogo-grey.png')} className='past-client-logos' alt='Sacramento International Airports' id='sIALogo' />
             </a>:  <a href='https://sacramento.aero/smf' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/SIALogo.png')} className='past-client-logos' alt='Sacramento International Airports' id='sIALogo' />
                </a>}
                </div>
                <div onMouseOver={()=>setLogo5(true)} onMouseLeave={()=> setLogo5(false)}>
                    { logo5 === false? 
                <a href='https://www.cdph.ca.gov/' target='_blank' rel="noopener noreferrer">
                <img src={require('../images/CDPH-Logo-grey.png')} className='past-client-logos' alt='Unilever' id='unileverLogo' />
            </a>: <a href='https://www.cdph.ca.gov/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/CDPH-Logo.png')} className='past-client-logos' alt='Unilever' id='unileverLogo' />
                </a>}
                </div>
                <div onMouseOver={()=>setLogo6(true)} onMouseLeave={()=> setLogo6(false)}>
                    { logo6 === false? 
                 <a href='https://dot.ca.gov/' target='_blank' rel="noopener noreferrer">
                 <img src={require('../images/ctlogo-grey.png')} className='past-client-logos-small' alt='British Airways' />
             </a> :  <a href='https://dot.ca.gov/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/ctlogo.png')} className='past-client-logos-small' alt='British Airways' />
                </a>}
                </div>
                <div onMouseOver={()=>setLogo7(true)} onMouseLeave={()=> setLogo7(false)}>
                    { logo7 === false? 
                 <a href='https://www.car.org/' target='_blank' rel="noopener noreferrer">
                 <img src={require('../images/car-logo-grey.png')} className='past-client-logos-small3' alt='car' />
             </a> :  <a href='https://www.car.org/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/car-logo.png')} className='past-client-logos-small3' alt='car' />
                </a>}
                </div>

                <div onMouseOver={()=>setLogo8(true)} onMouseLeave={()=> setLogo8(false)}>
                    { logo8 === false? 
                 <a href='https://www.ots.ca.gov/media-and-research/campaigns/new-logo/' target='_blank' rel="noopener noreferrer">
                 <img src={require('../images/ots-logo-grey.png')} className='past-client-logos-small2' alt='ots' />
             </a> :  <a href='https://www.ots.ca.gov/media-and-research/campaigns/new-logo/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/ots-logo.png')} className='past-client-logos-small2' alt='ots' />
                </a>}
                </div>

                <div onMouseOver={()=>setLogo9(true)} onMouseLeave={()=> setLogo9(false)}>
                    { logo9 === false? 
                 <a href='http://www.dbw.ca.gov/' target='_blank' rel="noopener noreferrer">
                 <img src={require('../images/dbw-logo-grey.png')} className='dbw' alt='dbw1' />
             </a> :  <a href='http://www.dbw.ca.gov/' target='_blank' rel="noopener noreferrer">
                    <img src={require('../images/dbw-logo.png')} className='dbw' alt='dbw1' />
                </a>}
                </div>

                
                



              

             

               

                

               

            </div>
            
        </div>
    )
}

export default PastClientLogos
