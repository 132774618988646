import React from 'react'
import ReactPlayer from 'react-player'
const MarketingVideo = () => {
    return (
        <div className='marketing-video-container'>
            <ReactPlayer                                                    
            className="videoFrame"
            url={"https://gds-content2021.s3-us-west-1.amazonaws.com/GleesonDigital_WebIntro_10k.mp4"}
            playing={false}
            controls={true}
            loop={false}
            muted={false}
            width={'100%'}
            height={'100%'}
            />
            {/* <ReactPlayer
                // url={require('../videos/Mod4Market365.mov')}
                className="videoFrame"
                // url={require('../images/carolgleesonimg.png')}
                url='https://gdsvideos22.s3.us-east-2.amazonaws.com/GleesonDigital_WebIntro_50k.mp4'
                
                width={'100%'}
                height={'100%'}
                playing={false}
                loop={false}
                // light={true}
                muted={false}
                controls={true}
                playIcon={true}
                className='marketing-video-player'
            /> */}
        </div>
    )
}
export default MarketingVideo