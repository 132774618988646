import React from 'react'
import ReactPlayer from 'react-player'
const CaseStudyMarketingVideo = () => {
    return (
        <div className='marketing-video-container'>
            <ReactPlayer
                // url={require('../videos/Mod4Market365.mov')}
                url='https://gds-content2021.s3-us-west-1.amazonaws.com/GleesonDigital_CaseStudyIntro_10K.mp4'
                width={'100%'}
                height={'100%'}
                playing={false}
                loop={false}
                muted={false}
                controls={true}
                playIcon={true}
                className='marketing-video-player'
            />
        </div>
    )
}
export default CaseStudyMarketingVideo