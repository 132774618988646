import React from 'react'
import ContactUsForm from './ContactUsForm'


const ContactUs = () => {

    return (
        <div className = "contactUs-bg">
            <div className = "contactUs-container">
                <div className = "contactUs-leftDiv" >
                    <div className = {"contactus-welcome-div"}>
                        <p className={"contactUs-welcome-header"}>We'd love to hear from you!</p>
                        <p  className={"contactUs-welcome-body"}>
                            If you think that GDS would be a good fit for your needs, or if you’d like us to contact you directly, please send us a note by filling out the information on this page.
                            <br/>
                            <br/>
                            Thank you for visiting and we look forward to helping you.</p>
                    </div>
                </div>
                <div className = "contactUs-form">
                <ContactUsForm/>
                </div>
            </div>
        </div>
    )

}

export default ContactUs