//Work on this

import React from 'react'
import Navbar from "../components/Navbar"

const Header = () => {

    return (
        <div className="header">
            <div className="header-name">
                <img className="headerLogo " src={require('../images/gdslogobg.svg')} alt="Gleeson Digital Strategies Logo" />
            </div>
            <div className="header-nav">
                <Navbar />
            </div>
        </div>
    )
}

export default Header
