import React from 'react'
import { useHistory } from 'react-router-dom';


const AllInsightsCard = (props) => {
    const history = useHistory()
    const handleInsightsDrillDown = () => {
        history.push(`/Insight/${props.insight.referenceNumber}`)
    }

    return (
        <div>
            <div onClick={handleInsightsDrillDown} className="insights-card-container">
                <h1 className="insights-card-title">{props.insight.title}</h1>
                <p className="insights-card-article"> {"Source: "+ props.insight.company} </p>
                <div className="insights-card-p-div">
                    <p className="insights-card-p-tag-content"> {props.insight.content} </p>
                    <p className="insights-card-date"> {props.insight.date.split("T")[0]} </p>
                    <p className="insights-card-date"> View More </p>
                </div>
            </div>
        </div>

    )
}

export default AllInsightsCard