import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import AboutDescription from '../components/AboutDescription'
import OurStaff from "../components/OurStaff"

const About = () => {

    return (
        <div >
            <Header />
            <div className='about-page'>
                <AboutDescription />
                <p className='our-staff-title'>Our Team</p>
                <OurStaff />
                <Footer />
            </div>
        </div>
    )
}

export default About