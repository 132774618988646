import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AllInsights from '../components/AllIInsights'
import NoInsights from '../components/NoInsights'
import Loader from '../components/Loader'


const Insights = () => {


    const [allInsights, setAllInsights] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetch("https://gds-core-heroku-22.herokuapp.com/insights")
            .then(resp => resp.json())
            .then(data => {
                let liveInsights = []
                data.forEach(insight => {
                    insight.isLive === true && liveInsights.push(insight)
                })
                const sortedInsights = liveInsights.sort((a, b) => {
                    var c = new Date(a.date);
                    var d = new Date(b.date);
                    return d - c;
                })
                setAllInsights(sortedInsights)
                setLoading(false)
            })
    }, [])

    return (
        <div className="insightsPage">
            <Header />
            {allInsights && (allInsights.length === 0 || undefined) && loading === false ? <NoInsights /> : <AllInsights allInsights={allInsights} />}
            {loading === true && <Loader />}

            <Footer />
        </div>
    )
}

export default Insights