import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import Home from './containers/Home';
import About from './containers/About';
import Contact from './containers/Contact';
import Casestudy from './containers/Casestudy';
import Insights from './containers/Insights';
import Insight from './containers/Insight';



function App() {
  return (
    <Switch>
      <Route exact path="/" render={() => <Home />} />
      <Route exact path="/about" render={() => <About />} />
      <Route exact path="/contact" render={() => <Contact />} />
      <Route exact path="/casestudy" render={() => <Casestudy />} />
      <Route exact path="/insights" render={() => <Insights />} />
      <Route relative path="/Insight" render={() => <Insight />} />
      <Route exact path="*" render={() => <Home />} /> 
    </Switch>
  );
}

export default App;
