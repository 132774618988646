import React, { useState, useEffect } from 'react'

import Header from "../components/Header"
import Footer from '../components/Footer'
import CaseStudiesSingleStudy from '../components/CaseStudiesSingleStudy';
import CaseStudyMarketingVideo from '../components/CaseStudyMarketingVideo';
import CasestudyDemo from '../components/CasestudyDemo';
import Loader from '../components/Loader';



const Casestudy = () => {

    const [caseStudyData, setCaseStudyData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch('https://gds-core-heroku-22.herokuapp.com/caseStudies')
            .then(resp => resp.json())
            .then(data => {
                //Sort caseStudies by date from newest to oldest....
                const sortedCaseStudies = data.sort((a, b) => {
                    var c = new Date(a.date);
                    var d = new Date(b.date);
                    return d - c;
                })
                setCaseStudyData(sortedCaseStudies)
                setLoading(false)
            })
    }, [])


    return (
        <div>
            <Header />
            {/* Intro Section */}
            <div className='case-study-page'>
                <div className='intro-container'>
                    <div className='intro-desc'>
                        <p className='home-and-cs-header-title'>HOW OUR CASE STUDIES WORK</p>
                        <p id="intro-desc-top-p">Our case studies provide a glimpse into the level of our strategic thinking, how we use data to provide insights, and the creative tactics deployed.</p>
                    </div>
                    <div className="intro-video">
                        <CaseStudyMarketingVideo />
                    </div>
                </div>
                {/* Top 3 Case Studies */}

                <CasestudyDemo />

                {loading === true ? <Loader /> : caseStudyData.map(caseStudy => {
                    return caseStudy.isLive === true && <>  <hr className="hrThin" width='80%' /> <CaseStudiesSingleStudy caseStudyDemo={caseStudy} /> </>
                })}
            </div>
            <Footer />
        </div>
    )

}

export default Casestudy