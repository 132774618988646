import React, { useState } from "react"
import StudyModal from './StudyModal'




const Services = () => {

    const [showVideoModal, setShowVideoModal] = useState(false)
    const [showTitle, setShowTitle] = useState(null)
    const [showURL, setShowURL] = useState(null)
    const [showContent, setShowContent] = useState(null)


    const showVideo1 = () => {
        setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/1_FollowTheData_06.mp4')
        setShowTitle("Follow The Data")
        setShowVideoModal(true);
    }

    const showVideo2 = () => {
        setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/2_NextLevelAudienceTargeting_05.mp4')
        setShowTitle("Next Level Audience Targeting")
        setShowVideoModal(true);
    }

    const showVideo3 = () => {
        setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/3_Real+Time+Optimization_06.mp4')
        setShowTitle("Real Time Optimization")
        setShowVideoModal(true);
    }

    const showVideo4 = () => {
        setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/4_AlwaysOnMindset_03.mp4')
        setShowTitle("Always On Mindset")
        setShowVideoModal(true);
    }

    const showVideo5 = () => {
        setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/5_TechnologyAndInsights_04.mp4')
        setShowTitle("Insights")
        setShowVideoModal(true);
    }

    const resetProps = () => {
        setShowVideoModal(false);
        setShowTitle(null);
        setShowURL(null);
        setShowContent(null);
    }

    return (
        <div className="services-container" >
            <StudyModal
                showVideoModal={showVideoModal}
                setShowVideoModal={setShowVideoModal}
                showTitle={showTitle}
                showURL={showURL}
                showContent={showContent}
                resetProps={resetProps}
            />
            <div className='services-container-information'>
                <div className="services-header">
                    <p className="expertise-header">Our Services</p>
                    <p id='services-start-today'>Get Started with our team today</p>
                    <div className="services-flex-box-cirlces-container">
                        <div className='services-flex-box-cirlce-1' onClick={showVideo1}>
                            <h1 className='services-flex-box-cirlce-title'>Follow <br />the Data</h1>
                        </div>
                        <div className='services-flex-box-cirlce-2' onClick={showVideo2}>
                            <h1 className='services-flex-box-cirlce-title'>Next Level <br /> Audience Targeting</h1>
                        </div>
                        <div className='services-flex-box-cirlce-5' onClick={showVideo5}>
                            <h1 className='services-flex-box-cirlce-title'>Insights</h1>
                        </div>
                        <div className='services-flex-box-cirlce-4' onClick={showVideo3}>
                            <h1 className='services-flex-box-cirlce-title' >Real Time <br /> Optimization</h1>
                        </div>
                        <div className='services-flex-box-cirlce-3' onClick={showVideo4}>
                            <h1 className='services-flex-box-cirlce-title' > Always On <br /> Mindset</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services

