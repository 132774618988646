import React from 'react'

const IntroDesc = () => {


  return (
    <div className="intro-desc-information">
      <p className='home-and-cs-header-title'>
      STRATEGIC THINKING,
      CREATIVITY,
      <br/>
      DEEP TECHNICAL UNDERSTANDING,
      <br/>
      CREATE SMART RESULTS-DRIVEN 
      <br/>
      COMMUNICATION. </p>    
      {/* <p id="intro-desc-top-p">All of our services have been crafted with our customers in mind. Our high performing Digital Marketing services have an industry renowned track record for providing monumental value at every step of the journey. We work collaboratively with an aligned outcome.
        <br /><br />We believe a new (agency) model has to develop. It has to be faster, it has to be better, and it has to be more efficient.
        </p> */}
      {/* <p id="intro-desc-p">- Sir Martin Sorrell</p> */}
      {/* <button className='intro-desc-contact-btn' onClick={() => handleContactUsClick()}> CONTACT US </button> */}
    </div>
  )
}

export default IntroDesc