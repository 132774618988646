import React from 'react'


const ClientAdv = () => {

    return (
        <div className="client-adv-container">
            <div className="client-adv2-bg">

                <img className="client-adv2-bg-small" src={require('../images/client-adv-bg-big.png')} alt="Brand Partners" />

                <div className="client-adv-desc">
                    <span className="client-adv-desc-p1"> Some of Our Partners</span>
                    <p className="client-adv-line"> </p>
                    {/* <span className="client-adv-desc-p2">  We partner with a </span>
                    <span className="client-adv-desc-p2-white">   multitude of brands </span>
                    <span className="client-adv-desc-p2">  around the United States to maximize our clients media marketing efforts </span> */}
                </div>
            </div>
        </div>
    )
}

export default ClientAdv

