import React from 'react'

const OurStaff = () => {

    return (
        <div>
            <div className='our-staff-flexbox-container'>
                <div className='our-staff-flexbox-container-left'>
                    <img src={require('../images/carolProfile.jpg')} className='our-staff-flexbox-container-left-image' alt="Carol Gleeson Headshot" />
                    <p className='our-staff-flexbox-container-name-1'>
                        Carol Gleeson
                    </p>
                    <div className='our-staff-flexbox-container-left-image-container'>
                        <div className='our-staff-flexbox-container-left-image-container-left'>
                            <img src={require("../images/carolBioPic1.png")} className='carolBioPics' alt='grand prix award' />
                        </div>
                        <div className='our-staff-flexbox-container-left-image-container-right'>
                            <img src={require("../images/carolBioPic2.png")} className='carolBioPics' alt='cannes lions' />
                        </div>
                    </div>
                </div>
                <div className='our-staff-flexbox-container-right'>
                    <p className='our-staff-flexbox-container-bio'>
                        Carol Gleeson is a rare breed of strategist, endlessly curious, constantly evolving, always doing
                        things the proper way –guided by the values instilled by her Irish ancestors who settled the new
                        world of New Zealand and made it home.
                    <br />
                        <br />
                    Carol’s professional journey started in New Zealand with media powerhouse Saatchi &amp; Saatchi.
                    Known for fusing emerging technologies with her commitment to excellence, Carol’s big data
                    skills saw her promoted to the Sydney offices of Saatchi &amp; Saatchi and later ZenithOptimedia.
                    <br />
                        <br />
                    All before she turned 25 – let that sink in.
                    <br />
                        <br />
                    Known as an out-of-the box thinker supporting some of the world’s leading brands, Carol’s data
                    driven mindset attracted the attention of OMD’s New York office who lured her to the United
                    States to help launch Cingular Wireless’ billion-dollar rollout.
                    <br />
                        <br />
                    With Gleeson Digital Strategies, Carol builds upon her decades of experience working on behalf
                    of Fortune 100 Companies, while returning to a practice focused on direct relationships with her
                    clients.
                    <br />
                        <br />
                    </p>
                </div>
            </div>
            <hr width='60%' id="our-staff-hr" />
            <div className='our-staff-flexbox-container'>
                <div className='our-staff-flexbox-container-left'>
                    <img src={require('../images/otiliaProfile.jpg')} className='our-staff-flexbox-container-left-image' alt="Otilia Bretan Headshot" />
                    <p className='our-staff-flexbox-container-name-2'>
                        Otilia Bretan
                    </p>
                    <p className='our-staff-flexbox-container-title-2'>
                    </p>
                </div>
                <div className='our-staff-flexbox-container-right'>
                    <p className='our-staff-flexbox-container-bio'>
                        Over ten years, Otilia Bretan has seen her trajectory in media ascend from assistant buyer in a
                        mid-level regional agency to leadership roles designing both programmatic and paid social media
                        campaigns for governmental, corporate, and political campaigns for Gleeson Digital Strategies.
                    <br />
                        <br />
                    While Otilia has successfully designed and executed traditional media plans in some of the
                    largest markets in the nation, she’s made her mark with niche audience targeting and
                    programmatic delivery. Otilia’s experience in tough markets and her fluence in multiple
                    platforms ensures GDS clients are given the tools to grow market share and improve brand
                    awareness.
                    <br />
                        <br />
                    Yet, of all of her accolades, Otilia remains most proud of raising daughter Giulia and spirited toddler Lucas alongside her husband Marius.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default OurStaff