import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const Testimonials = () => {

    return (
        <div className="testimonials-container">
            <p className="questions-header">What They Say About Carol</p>
            <Carousel showArrows={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
                <div className="carousel-container">
                    <div className="testimonials-comments">
                        <p>“While the nation was reading headlines about beef supply chain disruptions and worker safety,
                        Gleeson Digital Strategies delivered our Resilience 2020 message that defended our image and
                        assured consumers that California's cattle ranchers were producing a safe, secure, and
                        environmentally sustainable product to feed their families.”
                        </p>
                        <p id='testimonial-comment-client-name'>Mark Lacey</p><p id='testimonial-comment-client-title'>Past President, California Cattlemen’s Association</p>
                    </div>
                    <div className="testimonials-image">
                        <img src={require('../images/markLacey.jpg')} className='testimonial-images' alt='test-1' />
                    </div>
                </div>
                <div className="carousel-container">
                    <div className="testimonials-comments">
                        <p>"Carol&#39;s ability to bring her years of big data experience and apply them to our
                        unique, and niche issues bring tremendous value to our entire organization. Carol is
                        also able to educate clients on how to maximize data usage and help make sense of the
                        data world to organizations that need to understand how it all works."
                        </p>
                        <p id='testimonial-comment-client-name'>Laiza Negrete</p><p id='testimonial-comment-client-title'>Vice President of Political Affairs California Association of REALTORS®</p>
                    </div>
                    <div className="testimonials-image">
                        <img src={require('../images/laiza.jpg')} className='testimonial-images' alt='test-2' />
                    </div>
                </div>
                <div className="carousel-container">
                    <div className="testimonials-comments">
                        <p>“Carol is a smart and sophisticated media entrepreneur who is deeply dedicated to the success of her
                        clients. In addition to always being at the forefront of media innovation, her ability to build
                        meaningful and long-lasting relationships with an injection of wit and humor makes working with
                        her a fun and joyful experience.”
                        </p>
                        <p id='testimonial-comment-client-name'>Shawn Riegsecker</p><p id='testimonial-comment-client-title'>Founder and CEO, Centro</p>
                    </div>
                    <div className="testimonials-image">
                        <img src={require('../images/ShawnRiegsecker.jpg')} className='testimonial-images' alt='test-2' />
                    </div>
                </div>
                <div className="carousel-container">
                    <div className="testimonials-comments">
                        <p>"The power of Carol's strategic thinking is only eclipsed by her deep commitment to
                        improving people's lives.  Her work on behalf of St. John's Program for Real Change
                        not only helped us amplify our message but also raised awareness within the region."
                        </p>
                        <p id='testimonial-comment-client-name'>Sasha Wirth</p><p id='testimonial-comment-client-title'>Vice President and Chief Development Officer at Saint John's Program for Real Change</p>
                    </div>
                    <div className="testimonials-image">
                        <img src={require('../images/sasha.jpeg')} className='testimonial-images' id='sasha' alt='test-2' />
                    </div>
                </div>
                <div className="carousel-container">
                    <div className="testimonials-comments">
                        <p>“Like my colleagues at Directions, Carol is at her core a data nerd.  Her ability to transform her data
                        skills into clear learnings and consumer targeting that the non-data nerds can understand is what
                        truly sets her apart.”
                        </p>
                        <p id='testimonial-comment-client-name'>Andy Hogan</p><p id='testimonial-comment-client-title'>Sr. Account Executive, Directions Research</p>
                    </div>
                    <div className="testimonials-image">
                        <img src={require('../images/andyHogan.jpg')} className='testimonial-images' alt='test-2' />
                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default Testimonials