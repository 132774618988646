import React from 'react'


const AboutDescription = (props) => {

    return (
        <div >
            {/* <div >
                <img src={require('../images/nothingImpossible.svg')} className='about-us-bottom-img' alt="Travelers over looking a beautiful valley river from a steep cliff top" />
            </div> */}
            <div className='about-us-section' >
                <p className='about-us-title'>ABOUT US</p>
                <div >
                    <p className='about-us-quote'>
                        “The environment has changed. It is 24/7, always-on.” - Sir Martin Sorrell
                    </p>
                </div>
                <div className='our-story-container-line'>
                </div>
                <p className='our-story-container-title'> OUR STORY </p>
                <div className='our-story-container-line'>
                </div>
                <div className='about-us-paragraph-container'>
                    <p className='about-us-paragraph'>
                        In today’s competitive market, being good isn’t enough. Those unable to move rapidly, adjust
                        immediately, and accept change as a constant are being left behind. Gleeson Digital Strategies
                        understands that your customers are online all the time and that digital, not analog, is the future.
                        To meet that challenge, our clients stay ahead because we are always on. Sure, we cut our
                        teeth buying traditional media in some of the hottest markets, but our primary focus is
                        delivering your content to the right audience, at the right time, wherever they are, regardless
                        of device.
                    <br />
                        <br />
                    With an agency pedigree developed at media powerhouses like Saatchi and Saatchi, Zenith
                    Optimedia, and OMD, burnished by brands such as Unilever, Dove, Magnum, SC Johnson and
                    Coca Cola, Gleeson Digital Strategies delivers best in class technology and a level of
                    experience rivaled by few.
                    <br />
                        <br />
                    But we’re not an agency, we are a two-person team who grew tired of the old model. Others
                    sell digital, we do digital – meaning we do the work. We seek direct relationships with high
                    value clients who share our “always on” mindset and who value winning.
                    <br />
                        <br />
                    Because being good isn’t enough.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutDescription