import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Loader from '../components/Loader'



const Insight = () => {

    const referenceNumber = window.location.pathname.split("/")[2]
    const [insightData, setInsightData] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch("https://gds-core-heroku-22.herokuapp.com/insights")
            .then(resp => resp.json())
            .then(data => {
                data.forEach(insight => {
                    if (insight.referenceNumber === referenceNumber) {
                        setInsightData(insight)
                        setLoading(false)
                    }
                })
            })
    }, [referenceNumber])



   const checkHttp= (data)=> {
           if(data.includes("http")){
            window.open(data, '_blank');
           } else {
            window.open('https://'+ data, '_blank')
           }
   } 

    return (
        <div>
            <Header />
            { loading === true? <Loader /> :

            <div className={"insight-container"} >
                <div className="insight-card-container">
                    <h1 className="insights-card-title">  {insightData !== "" ? insightData.title : "Loading Title..."} </h1>
                    <p className="insights-card-article"> {insightData !== "" ?"Source: " +insightData.company : "Loading Company..."} </p>
                    <p className="insights-card-p-tag"> {insightData !== "" ? insightData.content : "Loading Content..."}</p>
                  
                    <p className="insights-card-date"> {insightData !== "" ? insightData.date.split("T")[0] : "Loading Date..."} </p>

                    {insightData !== "" ? <> 

                    <button onClick={()=> checkHttp(insightData.url)} className='insight-button'>View Article</button> 
                    </> : "Loading Content..."}

                    <div className="insights-card-div" > </div>
                    {insightData !== "" && insightData.keyInsightOne !== null && <h3 className="insight-card-keyInsights">  Key Insights </h3>}
                    <div className="insights-card-p-div">
                        <p className="insights-card-p-tag">{insightData !== "" && insightData.keyInsightOne !== null && insightData.keyInsightOne}</p>
                    </div>
                    <br />
                    <div className="insights-card-p-div">
                        <p className="insights-card-p-tag"> {insightData !== "" && insightData.keyInsightOne !== null && insightData.keyInsightTwo} </p>
                    </div>
            


                </div>
            </div>
}

            <Footer />
        </div>
    )
}

export default Insight