import React from 'react'

import Header from "../components/Header"
import Footer from '../components/Footer'
import ContactUs from '../components/ContactUs'


const Contact = () => {

    return (
        <div>
            Contact
            <Header/>
            <ContactUs/>
            <Footer/>
        </div>
    )
}

export default Contact
