import React from 'react'
// import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';

export default class ContactUsForm extends React.Component {

    state = {
        name: "",
        email: "",
        phone: "",
        radioButtonEmail: false,
        radioButtonPhone: false,
        contactPreferance: "NA",
        message: "",
        sent: false
    }

    handleChangeName = (e) => {
        this.setState({ name: e.target.value })
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value })
    }

    handleChangePhone = (e) => {
        this.setState({ phone: e.target.value })
    }

    handleChangeRadio = (e) => {
        if (e.target.name === "phone" && this.state.contactPreferance !== "phone") {
            this.setState({
                contactPreferance: "phone",
                radioButtonPhone: true,
                radioButtonEmail: false
            })
        }
        else if (e.target.name === "phone" && this.state.contactPreferance === "phone") {
            this.setState({

                contactPreferance: "NA",
                radioButtonPhone: false
            })
        } else if (e.target.name === "email" && this.state.contactPreferance !== "email") {
            this.setState({
                contactPreferance: "email",
                radioButtonPhone: false,
                radioButtonEmail: true
            })
        } else if (e.target.name === "email" && this.state.contactPreferance === "email") {
            this.setState({

                contactPreferance: "NA",
                radioButtonEmail: false
            })
        }

    }


    handleChangeMessage = (e) => {
        this.setState({ message: e.target.value })
    }

    sendEmail = (e) => {
        e.preventDefault();
        const YOUR_SERVICE_ID = `${process.env.REACT_APP_YOUR_SERVICE_ID}`;
        const YOUR_TEMPLATE_ID = `${process.env.REACT_APP_YOUR_TEMPLATE_ID}`;
        const YOUR_USER_ID = `${process.env.REACT_APP_YOUR_USER_ID}`;
    
            emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, this.state, YOUR_USER_ID)
            .then((result) => {
                this.setState({ sent: true })
            }, (error) => {
            });
        }

    render() {

        if(this.state.sent === true){
            return(
                <div>
                    <h2 className={"white-text"}> Thank you! </h2>
                    <p className={"white-text"}> Your message has sent, we will get back to you as soon as possible. </p>
                    <hr className={"contact-Us-Hr"}></hr>
                </div>
            )
        } else if (this.state.sent === false){
        return (

            <form onSubmit={this.sendEmail}>
            <label className= "contactUs-form-label">Name </label>
            <br/>
            <input className="contactUs-form-input" onChange= {this.handleChangeName} type="text" name="name" />
            <br/>
            <br/>

            <label className= "contactUs-form-label" > Email </label>
            <br/>
            <input className="contactUs-form-input" onChange= {this.handleChangeEmail} type="text" name="email" />
            <br/>
            <br/>
            
            <label className= "contactUs-form-label" > Phone </label>
            <br/>
            <input className="contactUs-form-input" onChange= {this.handleChangePhone} type="text" name="phone" />
            <br/>
            <br/>

            <label className= "contactUs-form-label" > Prefered method of communication </label>
            <br/>
            <br/>

            <input className="contact-form-radio" onClick= {this.handleChangeRadio} type="radio" checked= {this.state.radioButtonPhone} name="phone" />
            <label className= "contactUs-form-label" > Phone </label>

            <input className="contact-form-radio" onClick= {this.handleChangeRadio} type="radio" checked= {this.state.radioButtonEmail} name="email" />
            <label className= "contactUs-form-label" > Email </label>
            <br/>
            <br/>

            <label className= "contactUs-form-label"> Message </label>
            <br/>
            <textarea className="contactUs-form-input" onChange= {this.handleChangeMessage} rows="3" cols="50" name="name" />
            <br/>
            <br/>
            
            <input className="contactUs-Form-btn" type="submit" value="Send" />
        </form>
        )
        }
    }
}