import React from 'react'

const NoInsights = () => {

    return (
        <div className={"noInsights-div"}>
            <h2 className={"white-text"}> No insights at this time... </h2>
        </div>
    )
}

export default NoInsights;