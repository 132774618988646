import React from "react"

import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from "react-icons/bs";


const Questions = () => {
    const [question4, setQuestion4] = useState(false)

    const [question1, setQuestion1] = useState(false)
    const [question2, setQuestion2] = useState(false)
    const [question3, setQuestion3] = useState(false)
    



    return (
        <div className="questions-container">
            <p className="questions-header">Frequently Asked Questions</p>
            <div className="questions-div">

                <div onClick={() => setQuestion1(!question1)} className="question-div">
                    <span className="question-span" >Do you purchase traditional media also?</span>
                    {question1 === true ? <span className="question-chevron-span"> <BsChevronUp /> </span> : <span className="question-chevron-span"> <BsChevronDown /> </span>}
                    {question1 === true ? <p className="question-answer-span">Yes!  We cut our teeth buying media for Unilever, British Airways and SC Johnson in some of the most competitive markets in the world.  We can plan and purchase your media in any market in the United States.</p> : null}
                </div>

                <div onClick={() => setQuestion2(!question2)} className="question-div">
                    <span className="question-span" >Do you design creative content for clients?</span>
                    {question2 === true ? <span className="question-chevron-span"> <BsChevronUp /> </span> : <span className="question-chevron-span"> <BsChevronDown /> </span>}
                    {question2 === true ? 
                    <p className="question-answer-span">
                    We are a small agency, and we stick to what we do best. Having said that, we’ve developed
                    relationships with a network of talented creative folks. Depending upon your needs, we will
                    absolutely engage one of our creative partners to meet your needs.</p> : null}
                </div>

                <div onClick={() => setQuestion3(!question3)} className="question-div">
                    <span className="question-span" >What do you mean when you say other firms “sell digital”?</span>
                    {question3 === true ? <span className="question-chevron-span"> <BsChevronUp /> </span> : <span className="question-chevron-span"> <BsChevronDown /> </span>}
                    {question3 === true ? <p className="question-answer-span">
                    Sadly, digital marketing is an industry full of broken promises, false guarantees, and snake oil
                    salesmen. Most agencies will tell you they do digital but in reality, they are merely selling you
                    digital executed by someone you don’t know or didn&#39;t hire. By selling digital, those agencies
                    actually commission someone else’s commission which results in less content served to your
                    audiences and you paying more than you should
                    <br/>
                    <br/>
                    Having a Demand Side Platform (DSP) in house means we design the audience targeting,
                    optimize your campaign in real time, and produce all of the analytics and data ourselves.</p> : null}
                </div>

                <div onClick={() => setQuestion4(!question4)} className="question-div">
                    <span className="question-span" >You quote Sir Martin Sorrell a couple of times on this site, why?</span>
                    {question4 === true ? <span className="question-chevron-span"> <BsChevronUp /> </span> : <span className="question-chevron-span"> <BsChevronDown /> </span>}
                    {question4 === true ? <p className="question-answer-span">
                    Martin Sorrell was the architect of the agency model that we have come to recognize.  At 75 years of age, he is once again at the forefront of modern thinking and has declared the model that he built as dead.  For the very man who shaped the agency model to, at his age, be advocating a new, cutting edge model is admirable.  I share his vision for a new, project based model that delivers a higher RIO for the client, and eliminates the bloat of an agency is a win win.
                    </p> : null}
                </div>
            </div>
        </div>
    )
}

export default Questions