import React from 'react'

const CaseStudiesSingleStudy = (props) => {

    return (
        <div className='case-study-page-single-study-section'>
            <h1 className='case-study-page-single-study-section-title'>{props.caseStudyDemo.title}</h1>
            <p className='case-study-page-single-study-section-client'>Client: {props.caseStudyDemo.client}</p>
            <img src={props.caseStudyDemo.csImage} className='case-study-page-single-study-section-image' alt='cs-1' />
            <p className='case-study-page-single-study-section-overview'>{props.caseStudyDemo.description}</p>
            <a href={props.caseStudyDemo.buttonLink} target="_blank" rel="noopener noreferrer"><button className='case-study-page-top-three-studies-card-button'>View Full Study</button> </a>
        </div>
    )
}

export default CaseStudiesSingleStudy