import React from 'react'
import AllInsightsCard from './AllInsightsCard'


const AllInsights = (props) => {

    const {allInsights} = props

    return (
        <div className="allInsights-container">
            <p className="allInsights-header">INSIGHTS</p>
            {allInsights && allInsights.map(insight => {
                return <AllInsightsCard insight={insight} key={insight.id} />
            })}
        </div>
    )
}

export default AllInsights