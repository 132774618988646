//Work on this
import React from 'react'

const Navbar = () => {

    return (
        <React.Fragment>
            <ul className='navBar'>
                <li>
                    <a href="/"> HOME</a>
                </li>
                <li>
                    <a href="/about"> ABOUT</a>
                </li>
                <li>
                    <a href="/casestudy"> CASE STUDIES</a>
                </li>
                <li>
                    <a href="/insights"> INSIGHTS</a>
                </li>
                <li>
                    <a href="/contact"> CONTACT</a>
                </li>
            </ul>
        </React.Fragment>
    )
}

export default Navbar