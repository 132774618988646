import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Modal } from 'semantic-ui-react'

const StudyModal = (props) => {
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <Modal
            closeIcon
            onClose={() => props.setShowVideoModal(false)}
            open={props.showVideoModal}
            dimmer={'blurring'}
        >
            <Modal.Content >
                <Modal.Description>
                    <div style={{ marginTop: 30 }}>
                        <ReactPlayer
                            url={props.showURL}
                            width={'100%'}
                            height={'100%'}
                            playing={isPlaying}
                            loop={false}
                            muted={false}
                            controls={true}
                            playIcon={true}
                            onReady={()=> setIsPlaying(true)}
                            className='marketing-video-player'
                        />
                        {props.showTitle != null ? <p style={{ fontSize: 20 }}>{props.showTitle} </p> : null}
                        {props.showContent != null ? <p> {props.showContent} </p> : null}
                    </div>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

export default StudyModal 