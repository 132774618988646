import React from 'react'
import Navbar from './Navbar'
import SocialMediaNavbar from './SocialMediaNavBar'

const Footer = () => {

    return (
        <div className="footer">
            <div className='footer-navbar-flexbox'>
                <div className='footer-navbar-flexbox-left'>
                    <Navbar />
                </div>
                <div className='footer-navbar-flexbox-middle'>
                    <div className='service-line-container'>
                        <p className='service-line'></p>
                    </div>
                </div>
                <div className='footer-navbar-flexbox-right'>
                    <SocialMediaNavbar />
                </div>
            </div>
            <div className="footer-copyright">
                <p className="footer-copyright-p">© 2021 Gleeson Digital Strategies. Managed by <a href="https://raptoritconsultants.com">Raptor IT Consultants</a></p>
            </div>
        </div>
    )
}

export default Footer