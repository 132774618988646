import React from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";


const Loader = () => {



    return (
        <div className={"noInsights-div"}>
            <p className={"loader"}> Loading </p>
            <PropagateLoader color={"#F24462"} loading={true} size={20} id="loader" />
        </div>
    )
}

export default Loader;