//Work on this
import React from 'react'
import { Icon } from 'semantic-ui-react'

const SocialMediaNavbar = () => {

    return (
        <React.Fragment>
            <ul className='socialMediaNavBar'>
                <li>
                    <a href="https://twitter.com/carol_gleeson" target='_blank' rel="noopener noreferrer"><Icon name='twitter' link size='large' /></a>

                </li>
            </ul>
        </React.Fragment>
    )
}

export default SocialMediaNavbar