import React from 'react'

import Header from '../components/Header'
import Intro from '../components/Intro'
import ClientAdv from '../components/ClientAdv'
import CasestudyDemo from '../components/CasestudyDemo'
import Services from '../components/Services'
import Testimonials from '../components/Testimonials'
import Questions from '../components/Questions'
import Footer from '../components/Footer'

const Home = () => {


    return (
        <div className="home">
            <Header />
            <div className="home-body">
                <Intro />
                <ClientAdv />
                <CasestudyDemo />
                <Services />
                <Testimonials />
                <Questions />
            </div>
            <Footer />
        </div>

    )

}

export default Home

