import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react'
import StudyModal from './StudyModal'







const CasestudyDemo = () => {

    const history = useHistory()
    const [showVideoModal, setShowVideoModal] = useState(false)
    const [showTitle, setShowTitle] = useState(null)
    const [showURL, setShowURL] = useState(null)
    const [showContent, setShowContent] = useState(null)


    const handleCaseStudyClick = () => {
        history.push('/casestudy')
    }

    const showModal = (e) => {
        if (e.target.id === "img1") {
            setShowTitle("High Stakes: Direct To Consumer Insurance Campaign")
            setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/CS_StateFund_04.mp4')
            setShowContent("Despite a booming pre-COVID economy, California’s $15 billion workers compensation insurance market not only saw a decline in premiums written but also saw increased competition among some of the nation’s largest insurers. State Compensation Insurance Fund, California's leading provider of workers' compensation insurance turned to Gleeson Digital Strategies to increase their market share. As of 2019, State Fund replaced Berkshire Hathaway as California’s number one underwriter of workerscompensation insurance.")
        } else if (e.target.id === "img2") {
            setShowTitle("Crisis Management: Defending the Cattle Industry’s Brand")
            setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/CS_Cattlemens_V3.mp4')
            setShowContent("At the onset COVID-19, California’s cattle industry faced immediate threats to its image from reports of supply chain, product, and worker safety issues. The California Cattle Council, the quasi-governmental umbrella organization of the cattle industry, retained Gleeson Digital Strategies to rapidly assure consumers and policy makers that the resilience of California’s beef and dairy families would ensure a plentiful and safe supply of beef and dairy products during this time of adversity.")
        } else if (e.target.id === "img3") {
            setShowTitle("Pro Bono: Raising Awareness For Change")
            setShowURL('https://gds-content2021.s3-us-west-1.amazonaws.com/CS_StJohns_07.mp4')
            setShowContent("Saint John’s Program for Real Change removes women and their children in the Sacramento region from the cycle of abuse. To keep the doors, open Saint John’s must continually expand its fundraising and awareness. Carol Gleeson’s passion for Saint John’s and her out-of-the box thinking leveraged her relationship with Entercom and amplified Saint John’s message tenfold. This award-winning campaign allowed Saint John’s to reach people they had never reached.")
        }
        setShowVideoModal(!showVideoModal)
    }


    return (
        <div className='case-study-demo-container'>
            <StudyModal
                showVideoModal={showVideoModal}
                setShowVideoModal={setShowVideoModal}
                showTitle={showTitle}
                showURL={showURL}
                showContent={showContent}
            />

           {window.location.href.includes("casestudy")? <p className="case-study-header"> CASE STUDIES </p>:  <p className="case-study-header"> Case Studies </p>}
            <div className="casestudy-demo-container" >
                <div className="case-study-demo-info">
                    <div className='case-study-demo-image-container' onClick={(e) => showModal(e)} >
                        <img id="img1" className="case-study-demo-image" src={require('../images/stateFund.png')} alt='image1' />
                        <Icon id="img1" name='play circle outline' link size='huge' className='case-study-demo-image-play-icon' />
                    </div>
                    <span className="case-study-demo-title">High Stakes:  Direct To Consumer Insurance Campaign</span>
                    <br />
                    <span className="case-study-demo-content">
                        Despite a booming pre-COVID economy, California’s $15 billion workers’ compensation insurance market
                        not only saw a decline in premiums written but also saw increased competition among some of the nation’s
                    largest insurers.<br /><br />
                    State Compensation Insurance Fund, California's leading provider of workers' compensation insurance turned
                    to Gleeson Digital Strategies to increase their market share. As of 2019, State Fund replaced Berkshire Hathaway
                    as California’s number one underwriter of workers compensation insurance.     </span>
                </div>
                <div className="case-study-demo-info" >
                    <div className='case-study-demo-image-container' onClick={(e) => showModal(e)} >
                        <img id="img2" className="case-study-demo-image" src={require('../images/cowsImg.jpg')} alt='image1' />
                        <Icon id="img2" name='play circle outline' link size='huge' className='case-study-demo-image-play-icon' />
                    </div>
                    <span className="case-study-demo-title">Crisis Management:  Defending the Cattle Industry’s Brand </span>
                    <br />
                    <span className="case-study-demo-content">
                        At the onset COVID-19, California’s cattle industry faced immediate threats to its image from reports of
                        supply chain disruptions and worker safety issues.  Executed on behalf of the California Cattle Council,
                        operating under the California Department of Food and Agriculture, the response from Gleeson Digital
                        Strategies to change the narrative was swift, decisive, and effective. </span>
                </div>
                <div className="case-study-demo-info" onClick={(e) => showModal(e)} >
                    <div className='case-study-demo-image-container'>
                        <img id="img3" className="case-study-demo-image" src={require('../images/realChange.jpg')} alt='image1' />
                        <Icon id="img3" name='play circle outline' link size='huge' className='case-study-demo-image-play-icon' />
                    </div>                    <span className="case-study-demo-title">Pro Bono: Raising Awareness For Change</span>
                    <br />
                    <span className="case-study-demo-content">
                    Saint John’s Program for Real Change removes women and their children in the Sacramento region
                    from the cycle of abuse. To keep the doors, open Saint John’s must continually expand its
                    fundraising and awareness. Carol Gleeson’s passion for Saint John’s and her out-of-the box
                    thinking leveraged her relationship with Entercom and amplified Saint John’s message tenfold. This
                    award-winning campaign allowed Saint John’s to reach people they had never reached. </span>

                </div>
            </div>
            { window.location.href.includes("casestudy") ? null :
                <div className="casestudy-demo-view-more-container">
                    <button className='intro-desc-contact-btn' onClick={() => handleCaseStudyClick()}> View More </button>
                </div>
            }
        </div >
    )
}

export default CasestudyDemo
